import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FrankieOne } from "./FrankieOne";
import EndScreen from "./EndScreen";
import { NotFoundScreen } from "./NotFoundScreen";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:entityId" element={<FrankieOne />} />
        <Route path="/endScreen" element={<EndScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
