import * as React from "react";

export const NotFoundScreen = () => {
  React.useEffect(() => {
    document.title = "Not Found";
  });
  return (
    <div className="not-found-wrapper">
      <div className="not-found-container">
        <div className="not-found-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="32"
            height="32"
            viewBox="0 0 48 48"
          >
            <path
              fill="#d50000"
              d="M5.7 22H42.5V26H5.7z"
              transform="rotate(-45.001 24.036 24.037)"
            ></path>
            <path
              fill="#d50000"
              d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M24,40c-8.8,0-16-7.2-16-16S15.2,8,24,8 s16,7.2,16,16S32.8,40,24,40z"
            ></path>
          </svg>
        </div>
        <h1 className="not-found-heading">Page Not Found</h1>
      </div>
    </div>
  );
};
