export const AvererContainer = (props) => {
  return (
    <div className="averer-container">
      <div className="averer-header">
        <img src="/averer-logo.svg" alt="Averer-logo" />
        <h2>
          Identity verification<span>_</span>
        </h2>
      </div>
      {props.children}
    </div>
  );
};
