import * as React from "react";
import "./LandingPage.css";
import { useParams } from "react-router-dom";
import { NotFoundScreen } from "./NotFoundScreen";
import { UUID_REGEX } from "./constants";
import { AvererContainer } from "./AvererContainer";

const DVS_URL = `${process.env.REACT_APP_DVS_URL}`;
const recipe = `${process.env.REACT_APP_DVS_RECIPE}`;
const WHITELISTED_DOMAIN = `${process.env.REACT_APP_WHITELISTED_DOMAIN}`;
const FRANKIE_CONFIG = {
  frankieBackendUrl: DVS_URL,
  documentTypes: [
    { type: "PASSPORT", acceptedCountries: "ALL", idExpiry: false },
  ],
  checkProfile: recipe,
  // we can custom the text using phrases
  phrases: {
    document: {
      // document.number is deprecated and will be replaced with "number" in each document type
      number: "Number",
      type_passport: {
        label: "Passport",
        subtitle: "recommended",
        number: "Document Number",
        country: "Country of Issue",
        expiry: "Expiry Date",
      },
    },
    document_uploads: {
      upload_success: "Uploaded",
    },
  },

  // this will control the welcome screen
  // or you can skip the welcome screen by simply setting this option to false
  welcomeScreen: {
    htmlContent: `<div class="welcome-screen">      
      <h3> Before you begin the ${process.env.REACT_APP_IDP_NAME} identity verification process </h3>
      <p>We need to collect some personal information to verify your identity before we can register you on the Redbelly Network.<br /><br >
      It’s important you enter your details as accurately as possible. This will save time and get you into your new account right away!</p>
      <h4>You’ll need:<h4>
      <ul>
        <li>5 mins of your time to complete this application</li>
        <li>Your passport</li>
      </ul>
      </div>`,
    ctaText: "Next",
  },
  pendingScreen: {
    ctaActions: [{ url: "/endScreen", text: "Finish" }],
  },
  successScreen: {
    ctaText: "Go back to the App",
    ctaUrl: "https://www.google.com",
  },
  failureScreen: {
    ctaText: "Failed",
    url: "/endScreen",
  },
  acceptedCountries: "ALL",
  idScanVerification: {
    releaseVersion: "v1.1",
    useMobileDevice: true,
    useLiveness: false,
  },
  maxAttemptCount: 5,
  googleAPIKey: false,
  requestAddress: false,
  consentText: `I consent to my personal information being collected and used in accordance with the Averer Pty Ltd <a href="https://www.averer.co/privacy-policy">Privacy Policy</a>. In addition, for the purposes of verifying my identity, I consent to: 
    <ol> 
      <li> The verification of my personal information with credit bureau header files (for verification only)</li> 
      <li> Against records held by official document issuers or official record holders via third party systems, accessed by our partner FrankieOne and </li> 
      <li> Verification agent(s) of Averer Pty Ltd acting as a nominated intermediary in accordance with Australian Privacy Principles. I consent to the use by third parties of the results of any verification checks on my identity for the purposes of monitoring and improving their verification services. Furthermore, I also agree to the <a href="https://www.redbelly.network/terms-and-conditions">terms and conditions</a> of Redbelly Network, and I acknowledge that these terms and conditions are subject to change from time to time.</li> 
    </ol> `,
  injectedCss: `#ff-onboarding-widget .ff-basic-button{
    font-family: 'Readex Pro', sans-serif !important;
    color: #fff !important;
    border-radius: 0 !important;
    padding: 12px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    }
 
    #ff-onboarding-widget button:disabled .ff-main-label {
      color: #9ea4aa !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-buttons button{
      flex: 1 !important;
    }
    #ff-onboarding-widget .ff-negative-margin-horizontal {
      margin-right: -20px !important;
      margin-left: -20px !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-document-review-input .ff-review-row{
      border-width: 8px !important;
      border-color:#f5f5f5 !important;
      padding-top: 17px !important;
      padding-bottom: 17px !important;
      
    }
    #ff-onboarding-widget .ff-review-input.ff-no-padding .ff-document-review-input .ff-review-row{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    #ff-onboarding-widget .ff-scrollable:not(.ff-no-padding) {
      padding-top:0px
  }
    #ff-onboarding-widget *{
      font-family: 'Readex Pro', sans-serif !important;
    }
    #ff-onboarding-widget .ff-basic-button:not(:disabled){
      background-color: #0070af !important;
    }

    .welcome-screen h3{
      margin: 24px 0px 26px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.3;
    }
    .welcome-screen h4{
      font-size: 11px;
      margin-top: 29px;
      margin-bottom: 22px;
    }
    .welcome-screen{
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.32px;
    }
    .welcome-screen ul{
      padding-left: 26px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal
    }
    .welcome-screen ul li {
      margin-bottom: 20px;
      position: relative;
      list-style: none;
    }
    .welcome-screen ul li:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.75 2.44 11.31 8l-5.56 5.56-1.06-1.06L9.19 8l-4.5-4.5 1.06-1.06z' fill='%23283A46'/%3E%3C/svg%3E");
      position: absolute;
      left: -27px;
      top: 4px;
    }
    .welcome-screen p {
      margin-top: 0;
      margin-bottom: 29px;

    }
    #ff-onboarding-widget .ff-welcome-content {
      text-align: left;
  }
  #ff-onboarding-widget .ff-title{
    margin-top: 20px !important;
    margin-bottom: 17px !important;
    min-height: 0 !important;
    font-size: 18px !important;
  }
  #ff-onboarding-widget .ff-welcome-subtitle, #ff-onboarding-widget .ff-welcome-hint {
    font-size: 16px;
    font-weight: normal !important;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.32px;
    margin-top: 0;
    margin-bottom: 26px
  }
    
  #ff-onboarding-widget .ff-welcome-view {
      text-align: center !important;
  
    }
    #ff-onboarding-widget .ff-progress {
      background: #293498 !important
    }
    #ff-onboarding-widget .ff-label .ff-main-label{
      font-family: 'Readex Pro', sans-serif !important;
      color: #0070af !important;
      font-size: 15px !important;
    }
    #ff-onboarding-widget {
      font-family: 'Readex Pro', sans-serif !important;
      color: #283a46 !important
    }

    #ff-onboarding-widget  .ff-buttton-wrapper{
      box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15) !important;
    }
   
    #ff-onboarding-widget .ff-router-frame .ff-progress-header {
      flex-basis: 58px !important;
      padding: 17px 20px !important;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
      margin-bottom: 10px;
      margin-top: 0 !important;
    }
   
    #ff-onboarding-widget .ff-document-form .ff-document-form-hint, #ff-onboarding-widget .ff-review-input .ff-document-form-hint{
      margin-top: 0 !important;
      font-size: 11px !important;
    }
    #ff-onboarding-widget .ff-subtitle{
      margin-bottom: 14px !important
    }
    #ff-onboarding-widget .message-footer {
      font-size: 11px;
    }
    #ff-onboarding-widget p {
      margin-bottom: 17px;
      margin-top: 0 !important
    }
    #ff-onboarding-widget .ff-document-form .ff-document-type, #ff-onboarding-widget .ff-review-input .ff-document-type{
      margin-top: 0 !important;
    }
    #ff-onboarding-widget .ff-document-type {
      margin-bottom: 30px;
    }
    #ff-onboarding-widget .ff-document-type .ff-prefixed-icon {
      max-width: 28px;
      color: #000 !important;
      margin-right: 20px !important;
    }
    #ff-onboarding-widget .ff-document-type .ff-goahead-button {
      height: 70px;
      margin-bottom: 10px !important;
      background: #f5f5f5;
      border: 1px solid #ecebec;
      border-radius: 8px;
    }
    #ff-onboarding-widget .ff-document-form .ff-label-wrapper,  #ff-onboarding-widget .ff-label-wrapper {
      padding-top: 22.5px !important;
    }
    #ff-onboarding-widget .vs__selected {
      font-weight: normal !important;
    }
    #ff-onboarding-widget .ff-label-wrapper .ff-label ~ .ff-label-wrapper {
      padding-top: 0 !important;
    }
    #ff-onboarding-widget .ff-document-form .ff-label, .ff-label-wrapper .ff-label {
      font-size: 12px !important;
    }
    #ff-onboarding-widget .ff-personal-details-form .ff-document-image, #ff-onboarding-widget .ff-review-input .ff-document-image, #ff-onboarding-widget .ff-document-form .ff-document-image, #ff-onboarding-widget .ff-document-form .ff-document-image-back, #ff-onboarding-widget .ff-review-input .ff-document-image, #ff-onboarding-widget .ff-review-input .ff-document-image-back {
      margin-top: 38px !important;
      width: 100% !important;
  }
  #ff-onboarding-widget .ff-review-input:not(.ff-review-summary){
    height: 100% !important;
  }
  #ff-onboarding-widget .ff-review-input .ff-form-container{
    overflow: visible !important;
  }
  #ff-onboarding-widget .ff-review-input .ff-form-container .ff-buttton-wrapper.ff-review-buttons.ff-extra-horizontal-padding {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
    #ff-onboarding-widget .ff-document-form .ff-label small, .ff-label-wrapper .ff-label small {
      font-size: inherit !important;
    }
    #ff-onboarding-widget .vs__search, #ff-onboarding-widget .vs__search:focus{
      padding: 3px 0px !important;
      margin: 0px 0 0 !important;
    }
    #ff-onboarding-widget .ff-document-form .ff-label + input, #ff-onboarding-widget .ff-label-wrapper .ff-label + input,  #ff-onboarding-widget .ff-label-wrapper .ff-label + select.ff-the-select-box {
      padding: 14px 16px;
      font-weight: normal !important;
      border-color: #ecebec !important;
      border-radius: 4px !important;
      font-family: 'Readex Pro', sans-serif !important;
      color: #283a46 !important;
    }
    #ff-onboarding-widget .ff-the-select-box, #ff-onboarding-widget .ff-document-form .ff-label + input {
      border-color: #ecebec !important;
      border-radius: 4px !important;
    }
    #ff-onboarding-widget .vs__selected-options span {
      padding: 0 !important;
      margin: 0;  
      color: #283a46;
    }
    #ff-onboarding-widget .ff-licence-inputs-container > .ff-label-wrapper {
      padding-top: 0 !important;
    }
    #ff-onboarding-widget .vs__selected-options .vs__search {
      padding-left: 0;
      margin-left: 0;
    }

    #ff-onboarding-widget .ff-document-form .ff-document-input, #ff-onboarding-widget .ff-review-input .ff-document-input {
      margin-top: 6px;
    }
    #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-document-image, #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-family-name, #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-gender, #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-marital-status, #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-middle-name, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-document-image, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-family-name, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-gender, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-marital-status, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-middle-name {
      margin-top: 26px !important;
      padding-top: 22px !important;
    }

    #ff-onboarding-widget .ff-tablet .ff-review-input .ff-fullname-input{
      flex: none !important;
    }
    
    #ff-onboarding-widget .vs__dropdown-option{
      height: 48px !important;
      font-weight: 400 !important;
      padding: 10px !important;
      border-color: #ecebec !important;
    }
    #ff-onboarding-widget .vs__open-indicator{
      fill: #283a46 !important;
      stroke-width: 1px;
      stroke: white;
    }
    #ff-onboarding-widget .ff-personal-details-form .ff-fullname-input .ff-given-name, #ff-onboarding-widget .ff-review-input .ff-fullname-input .ff-given-name, #ff-onboarding-widget .ff-personal-details-form .ff-address-manual-input .country,  #ff-onboarding-widget .ff-personal-details-form .ff-address-manual-input .ff-input, #ff-onboarding-widget .ff-review-input .ff-address-manual-input .ff-input{
      padding-top: 22px !important;
      margin-top: 26px !important;
    }
    #ff-onboarding-widget .ff-personal-details-form .ff-address-manual-input .country{
      margin-top: 5px !important;
    }
    .ff-results-display:empty {
      min-height: 30px !important;
    }

    #ff-onboarding-widget .ff-personal-details-form .ff-dob-input .ff-generic-input input {
      border-radius: 4px !important;
      padding: 10px 16px;
      min-height: 48px;
      font-weight: normal !important;
      font-family: inherit;
      color: #283a46;
      text-align: left;
  }

    #ff-onboarding-widget .ff-feedback.ff-dob-message.ff-subtitle, #ff-onboarding-widget .ff-feedback.ff-display-error {
      font-size: 11px;
      line-height: 1.4;
      margin-top: 28px !important;
      color: #283a46;
      font-wight: 400 !important;
    }
    #ff-onboarding-widget .ff-feedback.ff-display-error{
      color: #ff5050;
    }

    #ff-onboarding-widget .ff-feedback.ff-dob-message.ff-subtitle br.responsive {
      display: none;
    }
    #ff-onboarding-widget .ff-feedback.ff-dob-message.ff-subtitle span {
      font-weight: normal !important;
    }
    .ff-address-input.ff-mobile {
      margin-top: 0px !important;
    }
    #ff-onboarding-widget .ff-review-input.ff-no-padding {
      margin-top: 0;
    }
    #ff-onboarding-widget .ff-review-input .ff-applicant-review-input .ff-subtitle, #ff-onboarding-widget .ff-review-input .ff-review-title {
      font-size: 14px !important;
      font-weight: 400 !important;
      margin-bottom: 0 !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-row:after {
      background-color: #ecebec !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-label{
      color: #848b91 !important;
      font-size: 11px !important;
    }
    #ff-onboarding-widget .ff-review-input .ff-text-link{
      color: #0070af !important;
      font-size: 11px !important;
      font-weight: normal;
    }
    #ff-onboarding-widget .ff-review-input .ff-review-value{
      color: #283a46 !important;
      font-size: 11px !important;
    }
    #ff-onboarding-widget .ff-consent-input .ff-consent-check{
      font-size: 11px !important;
      color: #283a46 !important;
      line-height: 1.45 !important;
    }
    #ff-onboarding-widget .ff-consent-input .ff-consent-check .ff-the-tick {
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px !important;
      border-color: #ecebec !important;
    }
    #ff-onboarding-widget .ff-consent-input .ff-consent-check .ff-the-tick .ff-icon {
      width: 100% !important;
      height: 100% !important;
      border-radius: 4px !important;
      background-color:#0070af !important; 
    }
    #ff-onboarding-widget .ff-personal-review .ff-address-extended-input.ff-mobile {
      margin-top: 0 !important;
    }

    #ff-onboarding-widget .ff-outcome-message .ff-message-container {
      margin-top: 0px !important;
    }

    #ff-onboarding-widget .ff-outcome-message .ff-message-block .ff-title {
      margin-top: 14px !important;
      margin-bottom: 10px !important;
      padding: 0 10px !important;
      line-height: 1.2;
    }
    #ff-onboarding-widget .ff-warning-no-match .ff-message-block, #ff-onboarding-widget .ff-warning-partial-match .ff-message-block {
      background: rgb(255 255 182) !important;
    }
    #ff-onboarding-widget .ff-warning-no-match .ff-message-block .ff-icon, #ff-onboarding-widget .ff-warning-partial-match .ff-message-block .ff-icon {
      background: rgb(212 184 0) !important;
    }

    #ff-onboarding-widget .ff-error-no-match .ff-message-block, #ff-onboarding-widget .ff-error-partial-match .ff-message-block {
      background: rgb(255 237 237) !important;
    }
    #ff-onboarding-widget .ff-error-no-match .ff-message-block .ff-icon, #ff-onboarding-widget .ff-error-partial-match .ff-message-block .ff-icon {
      background: #d40300 !important;
    }

    #ff-onboarding-widget .ff-outcome-message .ff-extra-note, #ff-onboarding-widget .ff-outcome-message .ff-inner-paragraph, #ff-onboarding-widget .ff-outcome-message .ff-outer-paragraph {
      margin-bottom: 0 !important;
    }
    #ff-onfido-widget .onfido-sdk-ui-Theme-content>div .onfido-sdk-ui-PageTitle-titleWrapper {
      margin-bottom: 0em !important;
    }
    .ff-onfido-wrapper {
      --osdk-color-content-title: #283a46;
      --osdk-color-content-body: #283a46;
      --osdk-color-content-subtitle: #283a46;
      --osdk-font-size-subtitle: 16px;
      --osdk-font-weight-subtitle: 400;
      min-height: 100%;
      --osdk-color-background-button-primary: #0070af;
      --osdk-color-border-button-primary: #0070af;
      --osdk-border-radius-button: 2px;
    }
    #ff-onfido-widget {
      min-height: 100%;
      --osdk-color-background-button-primary: #0070af;
      --osdk-color-border-button-primary: #0070af;
      --osdk-border-radius-button: 2px;
      background: #f5f5f5 !important;
    }
    .onfido-sdk-ui-PageTitle-titleSpan{
      font-size: 18px !important;
    }
    .onfido-sdk-ui-PageTitle-title {
      margin-bottom: 17px !important;
    }
    #ff-onfido-wrapper p {
      margin-top: 0;
    }
    .onfido-sdk-ui-Theme-footer {
      height: 3.3em !important;
    }
    .ods-button{
      font-weight: 500 !important;
    }
    .onfido-sdk-ui-Uploader-contentWrapper {
      padding-top: 40px;
    }
    .onfido-sdk-ui-Uploader-imageQualityGuideRow{
      padding-top: 28px;
      display: grid;
      justify-content: center;
      grid-template-columns: auto auto;
      grid-gap: 35px;
    }
    .onfido-sdk-ui-Uploader-documentExampleLabel {
      font-size: 9px;
      padding: 0 25px;
      max-width: 106px;
    }
    .onfido-sdk-ui-Uploader-documentExampleImg {
      width: 106px;
      height: 67px;
  }
  ol {
    list-style-type: lower-alpha;
  }
    `,
};

export const FrankieOne = () => {
  const [, setStart] = React.useState(false);
  const [, setOpen] = React.useState(false);
  const [isValidEntityId, setValidEntityId] = React.useState(true);
  const [ffToken, setFfToken] = React.useState("");
  const { entityId } = useParams();
  const [applicants, setApplicants] = React.useState([]);

  window.addEventListener("FF_CHECK_RESULT", (e) => {
    if (e.detail.resultSlug !== "pending-success") {
      const newApplicant = [...applicants].filter(
        (item) => item.reference !== e.detail.applicant.customerReference
      );

      newApplicant.push({
        reference: e.detail.applicant.customerReference,
        name: e.detail.applicant.name.givenName,
        result: e.detail.resultSlug,
        entityId: e.detail.applicant.entityId,
      });
      setApplicants(newApplicant);

      if (e.detail.resultSlug === "success") {
        setTimeout(() => {
          setOpen(false);
          setStart(false);
        }, 3000);
      }
    }
    // else if (e.detail.resultSlug === "too-many-tries") {
    //   const duplicateResult = e.detail.checkSummary.checkResults.filter(res => res.type === "duplicate");

    //   if (duplicateResult) {
    //     // if duplicate entry is found, close the modal and update the IDP DB for that IDP Entity ID
    //     if (process.env.REACT_APP_WHITELISTED_DOMAIN) {
    //       window.top.postMessage("close", process.env.REACT_APP_WHITELISTED_DOMAIN);
    //     }
    //   }
    // }
  });

  window.addEventListener(
    "message",
    (event) => {
      if (event.origin !== WHITELISTED_DOMAIN) return;
      if (entityId.match(UUID_REGEX) && event.data.token) {
        setValidEntityId(true);
        setFfToken(event.data.token);
      } else {
        setValidEntityId(false);
      }
    },
    false
  );

  React.useEffect(() => {
    const initialiseWidget = async (applicantReference) => {
      try {
        window.frankieFinancial.initialiseOnboardingWidget({
          applicantReference: applicantReference, // This will be your applicants reference code and corresponds to the customer_reference value in the FrankieOne Core API
          config: FRANKIE_CONFIG,
          ffToken,
          width: `100%`,
          height: `100%`,
        });
      } catch (err) {
        console.log(err, "error");
      }
    };
    if (ffToken) initialiseWidget(entityId);
  }, [ffToken, entityId]);

  try {
    if (!WHITELISTED_DOMAIN) return <NotFoundScreen />;
    return isValidEntityId ? (
      <>
        <AvererContainer>
          <ff-onboarding-widget />
        </AvererContainer>
      </>
    ) : (
      <NotFoundScreen />
    );
  } catch (error) {
    return <NotFoundScreen />;
  }
};
